import React, { useState } from "react";
import { Alert, Button, ButtonGroup, Form } from "react-bootstrap";

function Clue26(props) {
    const [completed, setCompleted] = useState(false)
    const [hintShown, setHintShown] = useState(false)
    const [showButtons, setShowButtons] = useState(false)

    const [completedTemp, setCompletedTemp] = useState(false)

    const [inputText, setInputText] = useState('')
    const [alertShown, setAlertShown] = useState(false)

    const checkAnswer = () => {
        if (inputText.toLowerCase() === 'the quiet world') {
            setCompleted(true)
        } else {
            setInputText('')
            setAlertShown(true)
        }
    }

    return (
        <Form>
            <Form.Group>
                <Form.Label as={'h2'}>Clue #26</Form.Label>
            </Form.Group>
            <hr />
            <Form.Group>
                <Form.Label as={'h4'}>We've reached that point. We are almost at the end. But before I let you go I have to let you flex on me a little bit. When you are ready click the chicken. Have fun :) and remember what you are typing!</Form.Label>
            </Form.Group>
            <Button onClick={() => (hintShown ? setHintShown(false) : setHintShown(true))} style={{ backgroundColor: '#9A86A4', borderColor: 'black' }}>Hint</Button>
            {
                hintShown ?
                    <Form.Group>
                        <Form.Label as={'h5'} className='text-muted mt-2'>
                            Just be good at typing!(oh wait you are cracked) Or unethical I guess :)
                        </Form.Label>
                    </Form.Group>
                    :
                    null
            }
            <hr />

            <a onClick={() => setShowButtons(true)} rel="noopener noreferrer" className="w-100 d-flex justify-content-center" href="https://www.keyhero.com/custom-typing-test/my-love-for-beryl-2/" target="_blank">
                <img className="w-25" alt="chicken" src="https://ih1.redbubble.net/image.1039263962.6676/st,small,507x507-pad,600x600,f8f8f8.u1.jpg" />
            </a>
            {
                showButtons ?
                    <ButtonGroup vertical className="w-100">
                        <Button disabled={completedTemp} style={{ backgroundColor: '#9A86A4', borderColor: 'black' }} className="w-100 mt-2" onClick={() => setCompletedTemp(true)}>WPM {">"} 100</Button>
                        <Button disabled={completedTemp} style={{ backgroundColor: '#9A86A4', borderColor: 'black' }} className="w-100 mt-2" href="https://www.youtube.com/watch?v=MtN1YnoL46Q">WPM {"<"} 100</Button>
                    </ButtonGroup>
                    :
                    null
            }

            {
                completedTemp ?
                    <div>
                        <Form.Group>
                            <Form.Label className="mt-2" as={'h4'}>What was the name of the poem you just read?</Form.Label>
                            <Form.Control disabled={completed} value={inputText} type='text' size="lg" onChange={(e) => {
                                setInputText(e.target.value)
                                setAlertShown(false)
                            }} />
                        </Form.Group>

                        {
                            alertShown ?
                                <Alert className="mt-2 mb-0 text-center" variant="danger">EEEERRRRRRRRRR(*weird teacher noises*)</Alert>
                                :
                                null
                        }

                        <Button disabled={completed} style={{ backgroundColor: '#9A86A4', borderColor: 'black' }} className="w-100 mt-2" onClick={() => checkAnswer()}>Enter</Button>
                    </div>
                    :
                    null
            }

            {
                completed ?
                    <div className="mt-2">
                        <div className="d-flex justify-content-center w-100">
                            <img alt="celebration pic" className="w-25" src='https://lh3.googleusercontent.com/UUaVNHHg4Y0NTYAzk19Dbj89QbE4xUJaVD0f_OWKv4UlWP_LHBlM-LasVzzPCjiqpC-LyhzFfi3_P38w6PniEBYrszjrZNtJSFpCGdHpACUzerVLzcT_XqTe7IqzoyXv94JcFAuSLg=w2400' />
                        </div>
                        <Button className="mt-2 w-100" style={{ color: 'black', backgroundColor: '#F6C6EA', borderColor: 'black' }} onClick={() => props.next()}>Last Clue</Button>
                    </div>
                    :
                    null
            }
        </Form>
    )
}

export default Clue26