import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";

function Clue8(props) {
    const [date1Correct, setDate1Correct] = useState(false)
    const [date1Hint, setDate1Hint] = useState(false)
    const [date2Correct, setDate2Correct] = useState(false)
    const [date2Hint, setDate2Hint] = useState(false)
    const [date3Correct, setDate3Correct] = useState(false)
    const [date3Hint, setDate3Hint] = useState(false)
    const [date4Correct, setDate4Correct] = useState(false)
    const [date4Hint, setDate4Hint] = useState(false)
    const [date5Correct, setDate5Correct] = useState(false)
    const [date5Hint, setDate5Hint] = useState(false)

    return (
        <Form>
            <Form.Group>
                <Form.Label as={'h2'}>Clue #8</Form.Label>
            </Form.Group>
            <hr />
            <Form.Group>
                <Form.Label as={'h4'}>Hans is not the biggest fan of special days of the year. But when it comes to Beryl, theres an exception. Do you know all the days hes on the lookout for with you?(in order that they did or will occur)</Form.Label>
            </Form.Group>
            <hr />
            <Form.Group>
                <Form.Label>#1:</Form.Label>
                <Form.Control disabled={date1Correct} type='date' size="sm" onChange={(e) => {
                    if (e.target.value === '2021-08-28') {
                        setDate1Correct(true)
                    }
                }} />
                <Button onClick={() => (date1Hint ? setDate1Hint(false) : setDate1Hint(true))} style={{ backgroundColor: '#9A86A4', borderColor: 'black' }} size="sm">Hint</Button>
                {date1Hint ? <Form.Text>   Im pretty good at interogations huh</Form.Text> : null}
            </Form.Group>
            <Form.Group>
                <Form.Label>#2:</Form.Label>
                <Form.Control disabled={date2Correct} type='date' size="sm" onChange={(e) => {
                    if (e.target.value === '2022-05-15') {
                        setDate2Correct(true)
                    }
                }} />
                <Button onClick={() => (date2Hint ? setDate2Hint(false) : setDate2Hint(true))} style={{ backgroundColor: '#9A86A4', borderColor: 'black' }} size="sm">Hint</Button>
                {date2Hint ? <Form.Text>   It would be really bad if I didnt know this day. But I do. And I love it :). It's OFFICIALLY my favorite day.</Form.Text> : null}
            </Form.Group>
            <Form.Group>
                <Form.Label>#3:</Form.Label>
                <Form.Control disabled={date3Correct} type='date' size="sm" onChange={(e) => {
                    if (e.target.value === '2022-11-02') {
                        setDate3Correct(true)
                    }
                }} />
                <Button onClick={() => (date3Hint ? setDate3Hint(false) : setDate3Hint(true))} style={{ backgroundColor: '#9A86A4', borderColor: 'black' }} size="sm">Hint</Button>
                {date3Hint ? <Form.Text>   Me like cookie me so hungy me eat cookie</Form.Text> : null}
            </Form.Group>
            <Form.Group>
                <Form.Label>#4:</Form.Label>
                <Form.Control disabled={date4Correct} type='date' size="sm" onChange={(e) => {
                    if (e.target.value === '2023-03-14') {
                        setDate4Correct(true)
                    }
                }} />
                <Button onClick={() => (date4Hint ? setDate4Hint(false) : setDate4Hint(true))} style={{ backgroundColor: '#9A86A4', borderColor: 'black' }} size="sm">Hint</Button>
                {date4Hint ? <Form.Text>   Atleast Hans is cute hehe</Form.Text> : null}
            </Form.Group>
            <Form.Group>
                <Form.Label>#5:</Form.Label>
                <Form.Control disabled={date5Correct} type='date' size="sm" onChange={(e) => {
                    if (e.target.value === '2030-01-01') {
                        setDate5Correct(true)
                    }
                }} />
                <Button onClick={() => (date5Hint ? setDate5Hint(false) : setDate5Hint(true))} style={{ backgroundColor: '#9A86A4', borderColor: 'black' }} size="sm">Hint</Button>
                {date5Hint ? <Form.Text>   ITS SOOOOO HARD TO RESIST</Form.Text> : null}
            </Form.Group>



            {
                (date1Correct && date2Correct && date3Correct && date4Correct && date5Correct) ?
                    <div className="mt-2">
                        <div className="d-flex justify-content-center w-100">
                            <img alt="celebration pic" className="w-25" src='https://lh3.googleusercontent.com/UUaVNHHg4Y0NTYAzk19Dbj89QbE4xUJaVD0f_OWKv4UlWP_LHBlM-LasVzzPCjiqpC-LyhzFfi3_P38w6PniEBYrszjrZNtJSFpCGdHpACUzerVLzcT_XqTe7IqzoyXv94JcFAuSLg=w2400' />
                        </div>
                        <Button className="mt-2 w-100" style={{ color: 'black', backgroundColor: '#F6C6EA', borderColor: 'black' }} onClick={() => props.next()}>Next Clue</Button>
                    </div>
                    :
                    null
            }
        </Form>
    )
}

export default Clue8