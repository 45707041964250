import React, { useState } from "react";
import { Button, ButtonGroup, Form } from "react-bootstrap";

function Clue17(props) {
    const [completed, setCompleted] = useState(false)
    const [hintShown, setHintShown] = useState(false)

    return (
        <Form>
            <Form.Group>
                <Form.Label as={'h2'}>Clue #17</Form.Label>
            </Form.Group>
            <hr />
            <Form.Group>
                <Form.Label as={'h4'}>Sudoku has become one of our favorite passtimes! I love it with you Beryl. We just sit there and relax. But what if we couldent relax? What if we were being timed? What if we didnt finish it on time something bad would happen? Hmmmmm thats weird it looks like the clock is already ticking :) Good Luck and dont cheat!(Its recommended that you click the new game button till it says easy in the top left)</Form.Label>
            </Form.Group>
            <Button onClick={() => (hintShown ? setHintShown(false) : setHintShown(true))} style={{ backgroundColor: '#9A86A4', borderColor: 'black' }}>Hint</Button>
            {
                hintShown ?
                    <Form.Group>
                        <Form.Label as={'h5'} className='text-muted mt-2'>
                            Just be good at sudoku! Or unethical I guess :)
                        </Form.Label>
                    </Form.Group>
                    :
                    null
            }
            <hr />

            <div className="d-flex justify-content-center">
                <iframe title="sudoku" name="sudokuWindow2" src="https://123sudoku.co.uk/sudokulib/generate.php?size=medium" width='250' height='320' frameBorder="0" scrolling="no"></iframe>
            </div>

            <ButtonGroup vertical className="w-100">
                <Button disabled={completed} style={{ backgroundColor: '#9A86A4', borderColor: 'black' }} className="w-100 mt-2" onClick={() => setCompleted(true)}>Time {"<"} 7 mins</Button>
                <Button disabled={completed} style={{ backgroundColor: '#9A86A4', borderColor: 'black' }} className="w-100 mt-2" href="https://bkinsey314.wixsite.com/handbrecipes">Time {">"} 7 mins</Button>
            </ButtonGroup>

            {
                completed ?
                    <div className="mt-2">
                        <div className="d-flex justify-content-center w-100">
                            <img alt="celebration pic" className="w-25" src='https://lh3.googleusercontent.com/UUaVNHHg4Y0NTYAzk19Dbj89QbE4xUJaVD0f_OWKv4UlWP_LHBlM-LasVzzPCjiqpC-LyhzFfi3_P38w6PniEBYrszjrZNtJSFpCGdHpACUzerVLzcT_XqTe7IqzoyXv94JcFAuSLg=w2400' />
                        </div>
                        <Button className="mt-2 w-100" style={{ color: 'black', backgroundColor: '#F6C6EA', borderColor: 'black' }} onClick={() => props.next()}>Next Clue</Button>
                    </div>
                    :
                    null
            }
        </Form>
    )
}

export default Clue17