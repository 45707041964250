import React, { useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";

function Clue14(props) {
    const [completed, setCompleted] = useState(false)
    const [hintShown, setHintShown] = useState(false)

    const [inputText, setInputText] = useState('')
    const [alertShown, setAlertShown] = useState(false)

    const checkAnswer = () => {
        if (inputText.toLowerCase() === 'grom squad') {
            setCompleted(true)
        } else {
            setInputText('')
            setAlertShown(true)
        }
    }

    return (
        <Form>
            <Form.Group>
                <Form.Label as={'h2'}>Clue #14</Form.Label>
            </Form.Group>
            <hr />
            <Form.Group>
                <Form.Label as={'h4'}>Hans browses many of website in his free time. There is one website in particular though that he never uses. On this website there is an account impersonating him with his username(TheAwesome___)(fill in the blank). Find this account and enter the one comment that account has ever posted.</Form.Label>
            </Form.Group>
            <Button onClick={() => (hintShown ? setHintShown(false) : setHintShown(true))} style={{ backgroundColor: '#9A86A4', borderColor: 'black' }}>Hint</Button>
            {
                hintShown ?
                    <Form.Group>
                        <Form.Label as={'h5'} className='text-muted mt-2'>
                            Find Hans' reddit account and write what his one comment ever was.
                        </Form.Label>
                    </Form.Group>
                    :
                    null
            }
            <hr />
            <Form.Group>
                <Form.Label>Answer(no punctuation):</Form.Label>
                <Form.Control disabled={completed} value={inputText} type='text' size="lg" onChange={(e) => {
                    setInputText(e.target.value)
                    setAlertShown(false)
                }} />
            </Form.Group>

            {
                alertShown ?
                    <Alert className="mt-2 mb-0 text-center" variant="danger">EEEERRRRRRRRRR(*weird teacher noises*)</Alert>
                    :
                    null
            }

            <Button disabled={completed} style={{ backgroundColor: '#9A86A4', borderColor: 'black' }} className="w-100 mt-2" onClick={() => checkAnswer()}>Enter</Button>

            {
                completed ?
                    <div className="mt-2">
                        <div className="d-flex justify-content-center w-100">
                            <img alt="celebration pic" className="w-25" src='https://lh3.googleusercontent.com/UUaVNHHg4Y0NTYAzk19Dbj89QbE4xUJaVD0f_OWKv4UlWP_LHBlM-LasVzzPCjiqpC-LyhzFfi3_P38w6PniEBYrszjrZNtJSFpCGdHpACUzerVLzcT_XqTe7IqzoyXv94JcFAuSLg=w2400' />
                        </div>
                        <Button className="mt-2 w-100" style={{ color: 'black', backgroundColor: '#F6C6EA', borderColor: 'black' }} onClick={() => props.next()}>Next Clue</Button>
                    </div>
                    :
                    null
            }
        </Form>
    )
}

export default Clue14