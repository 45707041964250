import React from "react";
import { Form, Image } from "react-bootstrap";

function Clue27() {
    return (
        <Form>
            <Form.Group>
                <Form.Label as={'h2'}>Clue #27</Form.Label>
            </Form.Group>
            <hr />
            <Form.Group>
                <Form.Label as={'h4'}>Beryl I love you. I hope you enjoyed the game! It was a bunch of work to make in 2 days but it was fun and I will do anything just to put the smallest smile on your face. When you are ready for the surprise, click the picture of us.</Form.Label>
            </Form.Group>
            <hr />
            <div className="d-flex w-100 justify-content-center">
                <a className="w-50" href="http://hans.software" target="_blank" rel="noopener noreferrer">
                    <Image className="w-100 border border-dark rounded-2" src="https://lh3.googleusercontent.com/Kid9vYeTIvFXE2qNqJ2tZ66VekwPWnfJLTD0dsbIecRDtH6u0S8kFNAPtkDAJxcn5WEioc80JYuaGO8bSTm8exeB62hMS43G-BzJW0kU16ilSefNSlygv74nEIN7LM62lHRiitJC-Q=w2400" />
                </a>
            </div>
        </Form>
    )
}

export default Clue27