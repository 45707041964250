import React from "react";
import { Button, Form } from "react-bootstrap";

function StartCard(props) {
    return (
        <Form>
            <Form.Group>
                <Form.Label as={'h2'}>A Game to Show You My Love</Form.Label>
                <Form.Label as={'h4'} className='text-muted'>By: Hans Von Gruenigen</Form.Label>
            </Form.Group>
            <hr />
            <Form.Group>
                <Form.Label as={'h4'}>How to play:</Form.Label>
                <Form.Label as={'h4'}>Once you hit start, you will go from clue to clue to reach a surprise at the end! Each clue will lead you to a code or a phrase for you to input to get to the next clue. The most important thing for this game is to HAVE FUN. So enjoy! P.S. There are hints along the way but try not to use them.</Form.Label>
            </Form.Group>
            <Button className="float-end" style={{ color: 'black', backgroundColor: '#F6C6EA', borderColor: 'black' }} onClick={() => props.next()}>Start</Button>
        </Form>
    )
}

export default StartCard