import React, { useState } from "react";
import Clue1 from "./clues/Clue1";
import Clue2 from "./clues/Clue2";
import Clue3 from "./clues/Clue3";
import Clue4 from "./clues/Clue4";
import Clue5 from "./clues/Clue5";
import Clue6 from "./clues/Clue6";
import Clue7 from "./clues/Clue7";
import Clue8 from "./clues/Clue8";
import Clue9 from "./clues/Clue9";
import Clue10 from "./clues/Clue10";
import Clue11 from "./clues/Clue11";
import Clue12 from "./clues/Clue12";
import Clue13 from "./clues/Clue13";
import Clue14 from "./clues/Clue14";
import Clue15 from "./clues/Clue15";
import Clue16 from "./clues/Clue16";
import Clue17 from "./clues/Clue17";
import Clue18 from "./clues/Clue18";
import Clue19 from "./clues/Clue19";
import Clue20 from "./clues/Clue20";
import Clue21 from "./clues/Clue21";
import Clue22 from "./clues/Clue22";
import Clue23 from "./clues/Clue23";
import Clue24 from "./clues/Clue24";
import Clue25 from "./clues/Clue25";
import Clue26 from "./clues/Clue26";
import Clue27 from "./clues/Clue27";
import StartCard from "./StartCard";
import { Container, Row, Image, Col, Carousel, Modal, Form, Button, Alert, FormGroup, ButtonGroup } from "react-bootstrap";

const correctPass = '82821'

function App() {
  const [passwordModalShown, setPasswordModalShown] = useState(true)
  const [password, setPassword] = useState('')
  const [alertShown, setAlertShown] = useState(false)

  const [currentSlide, setCurrentSlide] = useState(0)
  const [highest, setHighest] = useState(currentSlide)

  const nextSlide = () => {
    setCurrentSlide(currentSlide + 1)
    if (currentSlide === highest) {
      setHighest(highest + 1)
    }
  }
  const startSlide = () => { setCurrentSlide(0) }
  const prieviousSlide = () => { setCurrentSlide(currentSlide - 1) }

  const qs = [
    <StartCard next={() => nextSlide()} />,
    <Clue1 next={() => nextSlide()} />,
    <Clue2 next={() => nextSlide()} />,
    <Clue3 next={() => nextSlide()} />,
    <Clue4 next={() => nextSlide()} />,
    <Clue5 next={() => nextSlide()} />,
    <Clue6 next={() => nextSlide()} />,
    <Clue7 next={() => nextSlide()} />,
    <Clue8 next={() => nextSlide()} />,
    <Clue9 next={() => nextSlide()} />,
    <Clue10 next={() => nextSlide()} />,
    <Clue11 next={() => nextSlide()} />,
    <Clue12 next={() => nextSlide()} />,
    <Clue13 next={() => nextSlide()} />,
    <Clue14 next={() => nextSlide()} />,
    <Clue15 next={() => nextSlide()} />,
    <Clue16 next={() => nextSlide()} />,
    <Clue17 next={() => nextSlide()} />,
    <Clue18 next={() => nextSlide()} />,
    <Clue19 next={() => nextSlide()} />,
    <Clue20 next={() => nextSlide()} />,
    <Clue21 next={() => nextSlide()} />,
    <Clue22 next={() => nextSlide()} />,
    <Clue23 next={() => nextSlide()} />,
    <Clue24 next={() => nextSlide()} />,
    <Clue25 next={() => nextSlide()} />,
    <Clue26 next={() => nextSlide()} />,
    <Clue27 />
  ]

  const checkPassword = (e) => {

    e.preventDefault()

    if (password === correctPass) {
      setPasswordModalShown(false)
    } else {
      setAlertShown(true)
    }
  }

  return (
    <div style={{ backgroundColor: '#CDF0EA', minHeight: '100vh', fontFamily: 'monospace' }}>
      <Modal show={passwordModalShown} fullscreen>
        <Modal.Header>
          <Modal.Title>Enter Password to Play Game:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container style={{ width: '400px' }}>
            <Form onSubmit={(e) => checkPassword(e)}>
              <Form.Group className="mb-3">
                <Form.Label>Password:</Form.Label>
                <Form.Control name="password" id="password" type="password" onChange={(e) => {
                  setPassword(e.target.value)
                  setAlertShown(false)
                }} />
              </Form.Group>
              {
                alertShown ?
                  <Alert variant="danger">Incorrect Password</Alert>
                  :
                  null
              }
              <FormGroup>
                <Form.Control className="btn btn-primary" type="submit" />
              </FormGroup>
            </Form>
          </Container>
        </Modal.Body>
      </Modal>
      {
        !passwordModalShown ?
          <div>
            <h1 style={{ textAlign: 'center', position: 'absolute', left: '0', right: '0', top: '0' }}>Hans' Super Secret Game</h1>
            <div className="mainDisplay" style={{ display: 'flex', justifyContent: 'center', overflowY: 'auto', padding: '20px', position: 'absolute', left: '0', right: '0', top: '40px', bottom: '60px' }}>
              <Container className="mainContainer" style={{ backgroundColor: '#FAF4B7', outlineStyle: 'solid', outlineWidth: '10px', overflow: 'auto' }}>
                <Row>
                  <Col style={{ padding: '10px' }} xs={5}>
                    <Carousel style={{ overflow: 'hidden' }} fade interval={null}>
                      <Carousel.Item><Image src="https://lh3.googleusercontent.com/COr4H34R4a0gV5rtWMbtCq6DyC8t90GtZBsa3M2gPTy6mTCnuyX_w_iG3CuVIC8rcRp1ZbsAigWGmYtQbQ4cC3sWx-NEef5HPatmY1n9k5c41Bf4QU6rLu6mgHTiyVSuG-7nN8FZnA=w2400" width={'100%'} /></Carousel.Item>
                      <Carousel.Item><Image src="https://lh3.googleusercontent.com/_qn67P5ZqXs-WY1aUw5aA86DEkA5L5GnmljPTuMFduHAghYgo0Z9fE0jMVIJCacMecECPIkYA7zhbP4JzjpXpf1sq8GPZ4da_QAlzXkEWzgYLPlcvdkP1Atf9YluW3YqGUY_I4VJug=w2400" width={'100%'} /></Carousel.Item>
                      <Carousel.Item><Image src="https://lh3.googleusercontent.com/CHQRUIU-aIMa1tVBWEGctzczz2jx7AYjP0dIcrE7T8Qas-WITN14_XsXGVZfT-kX3NMhjxHUO3eWfl1DbBn11vfFsrVvQGiCpw3OWTZl1xS-KRu0ERb1C0yqWdjOkQdNei_xU1WEcA=w2400" width={'100%'} /></Carousel.Item>
                      <Carousel.Item><Image src="https://lh3.googleusercontent.com/l8HhVFbzh6C_JZwDvQ4xTKeR3J2vCjfYxC-yg2Ss2JX5hg2UcpQ0H4NpU2E6_vhipgYdPr9CME1nOEuZ2cJwzfNFic05lkOnSaaZ_2JF8lU9ZJYH6XnGPPud_pRnkam3lZ9hIAvmyg=w2400" width={'100%'} /></Carousel.Item>
                    </Carousel>
                  </Col>
                  <Col style={{ padding: '10px' }} xs={7}>
                    {qs[currentSlide]}
                  </Col>
                </Row>
                <Row style={{ marginBottom: '10px' }}>
                  <ButtonGroup>
                    <Button style={{ color: 'black', backgroundColor: '#F6C6EA', borderColor: 'black' }} disabled={currentSlide === 0} onClick={() => prieviousSlide()}>Previous Clue</Button>
                    <Button style={{ color: 'black', backgroundColor: '#F6C6EA', borderColor: 'black' }} disabled={currentSlide === highest} onClick={() => nextSlide()}>Next Clue</Button>
                    <Button style={{ color: 'black', backgroundColor: '#F6C6EA', borderColor: 'black' }} disabled={currentSlide === 0} onClick={() => startSlide()}>Start Over</Button>
                  </ButtonGroup>
                </Row>
              </Container>
            </div>
            <h1 style={{ textAlign: 'center', position: 'absolute', left: '0', right: '0', bottom: '0' }}>For Beryl Kinsey My Forever Love</h1>
          </div>
          :
          null
      }
    </div >
  )
}

export default App;
