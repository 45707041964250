import React, { useState } from "react";
import { Button, Form, ListGroup, ListGroupItem } from "react-bootstrap";

const phrases = [
    "gavin",
    "gage",
    "steve"
]

function Clue15(props) {
    const [completed, setCompleted] = useState(false)
    const [hintShown, setHintShown] = useState(false)

    const [gottenAnswers, setGottenAnswers] = useState([])

    const [inputText, setInputText] = useState('')

    const answerInput = (text) => {
        if (!gottenAnswers.find(e => e === text.toLowerCase())) {
            const match = phrases.find(e => e === text.toLowerCase())

            if (match) {
                updateGotten(match)
            }
        }
    }

    const updateGotten = (newAnswer) => {
        let newAnswers = gottenAnswers.slice()
        newAnswers.push(newAnswer)
        setGottenAnswers(newAnswers)
        setInputText('')

        if (gottenAnswers.length === 2) {
            setCompleted(true)
        }
    }

    return (
        <Form>
            <Form.Group>
                <Form.Label as={'h2'}>Clue #15</Form.Label>
            </Form.Group>
            <hr />
            <Form.Group>
                <Form.Label as={'h4'}>Hans is mean to me but nice to me. Hans doesnt have many of me but the ones of me he has are quality. At times I can be crackhead or emotional or just plain dumb. I used to be Hans' alone but now I am yours too Beryl. What are my names?</Form.Label>
            </Form.Group>
            <Button onClick={() => (hintShown ? setHintShown(false) : setHintShown(true))} style={{ backgroundColor: '#9A86A4', borderColor: 'black' }}>Hint</Button>
            {
                hintShown ?
                    <Form.Group>
                        <Form.Label as={'h5'} className='text-muted mt-2'>
                            What are Hans' friend's names?
                        </Form.Label>
                    </Form.Group>
                    :
                    null
            }
            <hr />
            <Form.Group>
                <Form.Label>Answer(no punctuation):</Form.Label>
                <Form.Control disabled={completed} value={inputText} type='text' size="lg" onChange={(e) => {
                    setInputText(e.target.value)
                    answerInput(e.target.value)
                }} />
            </Form.Group>

            <div className="d-flex justify-content-center w-100">
                <ListGroup className="w-50 mt-2 p-1">
                    {
                        gottenAnswers.length === 0 ?
                            <ListGroupItem className="text-center">(no answers yet)</ListGroupItem>
                            :
                            null
                    }
                    {
                        gottenAnswers.map((P, i) => {
                            return (
                                <ListGroupItem className="text-center" key={i}>{P}</ListGroupItem>
                            )
                        })
                    }
                </ListGroup>
            </div>

            {
                completed ?
                    <div className="mt-2">
                        <div className="d-flex justify-content-center w-100">
                            <img alt="celebration pic" className="w-25" src='https://lh3.googleusercontent.com/UUaVNHHg4Y0NTYAzk19Dbj89QbE4xUJaVD0f_OWKv4UlWP_LHBlM-LasVzzPCjiqpC-LyhzFfi3_P38w6PniEBYrszjrZNtJSFpCGdHpACUzerVLzcT_XqTe7IqzoyXv94JcFAuSLg=w2400' />
                        </div>
                        <Button className="mt-2 w-100" style={{ color: 'black', backgroundColor: '#F6C6EA', borderColor: 'black' }} onClick={() => props.next()}>Next Clue</Button>
                    </div>
                    :
                    null
            }
        </Form>
    )
}

export default Clue15