import React, { useState } from "react";
import { Button, ButtonGroup, Container, Form, ListGroup, ListGroupItem, Row, Col } from "react-bootstrap";

const phrases = [
    "sharetea",
    "onezo",
    "tealicious",
    "gongcha",
    "fat strawz",
    "ding tea"
]

function Clue18(props) {
    const [completed, setCompleted] = useState(false)
    const [hintShown, setHintShown] = useState(false)

    const [gottenAnswers, setGottenAnswers] = useState([])

    const [allAnswersShown, setAllAnswersShown] = useState(false)

    const [inputText, setInputText] = useState('')

    const answerInput = (text) => {
        if (!gottenAnswers.find(e => e === text.toLowerCase())) {
            const match = phrases.find(e => e === text.toLowerCase())

            if (match) {
                updateGotten(match)
            }
        }
    }

    const updateGotten = (newAnswer) => {
        let newAnswers = gottenAnswers.slice()
        newAnswers.push(newAnswer)
        setGottenAnswers(newAnswers)
        setInputText('')

        if (gottenAnswers.length === 2) {
            setCompleted(true)
        }
    }

    return (
        <Form>
            <Form.Group>
                <Form.Label as={'h2'}>Clue #18</Form.Label>
            </Form.Group>
            <hr />
            <Form.Group>
                <Form.Label as={'h4'}>You could say this is where it all began. When alone here I mess up. When I'm with you I have the time of my life. Time does wild things where it seems the earth speeds up and its dark before I know it. All the good memories and deep conversations. Where are we?(You need 3 to pass)</Form.Label>
            </Form.Group>
            <Button onClick={() => (hintShown ? setHintShown(false) : setHintShown(true))} style={{ backgroundColor: '#9A86A4', borderColor: 'black' }}>Hint</Button>
            {
                hintShown ?
                    <Form.Group>
                        <Form.Label as={'h5'} className='text-muted mt-2'>
                            Hans' favorite boba places?
                        </Form.Label>
                    </Form.Group>
                    :
                    null
            }
            <hr />
            <Form.Group>
                <Form.Label>Answer(no punctuation):</Form.Label>
                <Form.Control value={inputText} type='text' size="lg" onChange={(e) => {
                    setInputText(e.target.value)
                    answerInput(e.target.value)
                }} />
            </Form.Group>

            <ButtonGroup className="w-100">
                <Button style={{ backgroundColor: '#9A86A4', borderColor: 'black' }} className="w-50" disabled>Score(max: {phrases.length}): {gottenAnswers.length}</Button>
                <Button style={{ backgroundColor: '#9A86A4', borderColor: 'black' }} className="w-50" onClick={() => (allAnswersShown ? setAllAnswersShown(false) : setAllAnswersShown(true))}>{allAnswersShown ? 'Hide All Possible Answers' : 'View All Possible Answers'}</Button>
            </ButtonGroup>

            <Container className="m-0 p-0">
                <Row className="m-0 p-0">
                    <Col className="m-0 p-0">
                        <ListGroup className="w-100 mt-2 p-1">
                            {
                                gottenAnswers.length === 0 ?
                                    <ListGroupItem className="text-center">(no answers yet)</ListGroupItem>
                                    :
                                    null
                            }
                            {
                                gottenAnswers.map((P, i) => {
                                    return (
                                        <ListGroupItem className="text-center" key={i}>{P}</ListGroupItem>
                                    )
                                })
                            }
                        </ListGroup>
                    </Col>
                    <Col className="m-0 p-0">
                        {
                            allAnswersShown ?
                                <ListGroup className="w-100 mt-2 p-1">
                                    {
                                        phrases.map((P, i) => {
                                            return (
                                                <ListGroupItem className="text-center" key={i}>{P}</ListGroupItem>
                                            )
                                        })
                                    }
                                </ListGroup>
                                :
                                null
                        }
                    </Col>
                </Row>
            </Container>

            {
                completed ?
                    <div className="mt-2">
                        <div className="d-flex justify-content-center w-100">
                            <img alt="celebration pic" className="w-25" src='https://lh3.googleusercontent.com/UUaVNHHg4Y0NTYAzk19Dbj89QbE4xUJaVD0f_OWKv4UlWP_LHBlM-LasVzzPCjiqpC-LyhzFfi3_P38w6PniEBYrszjrZNtJSFpCGdHpACUzerVLzcT_XqTe7IqzoyXv94JcFAuSLg=w2400' />
                        </div>
                        <Button className="mt-2 w-100" style={{ color: 'black', backgroundColor: '#F6C6EA', borderColor: 'black' }} onClick={() => props.next()}>Next Clue</Button>
                    </div>
                    :
                    null
            }
        </Form>
    )
}

export default Clue18