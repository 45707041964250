import React, { useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";

function Clue25(props) {
    const [completed, setCompleted] = useState(false)
    const [completedInt, setCompletedInt] = useState(false)
    const [hintShown, setHintShown] = useState(false)

    const [inputText, setInputText] = useState('')
    const [inputText2, setInputText2] = useState('')
    const [alertShown, setAlertShown] = useState(false)
    const [alert2Shown, setAlert2Shown] = useState(false)

    const checkAnswer = () => {
        if (inputText.toLowerCase() === 'forever') {
            setCompletedInt(true)
        } else {
            setInputText('')
            setAlertShown(true)
        }
    }

    const checkAnswer2 = () => {
        if (inputText2.toLowerCase() === 'ever') {
            setCompleted(true)
        } else {
            setInputText2('')
            setAlert2Shown(true)
        }
    }

    return (
        <Form>
            <Form.Group>
                <Form.Label as={'h2'}>Clue #25</Form.Label>
            </Form.Group>
            <hr />
            <Form.Group>
                <Form.Label as={'h4'}>And how long will Hans love her for?</Form.Label>
            </Form.Group>
            <Button onClick={() => (hintShown ? setHintShown(false) : setHintShown(true))} style={{ backgroundColor: '#9A86A4', borderColor: 'black' }}>Hint</Button>
            {
                hintShown ?
                    <Form.Group>
                        <Form.Label as={'h5'} className='text-muted mt-2'>
                            No hint needed for this. Its obvious when you her :)
                        </Form.Label>
                    </Form.Group>
                    :
                    null
            }
            <hr />
            <Form.Group>
                <Form.Label>Answer(no punctuation):</Form.Label>
                <Form.Control disabled={completedInt} value={inputText} type='text' size="lg" onChange={(e) => {
                    setInputText(e.target.value)
                    setAlertShown(false)
                }} />
            </Form.Group>

            {
                alertShown ?
                    <Alert className="mt-2 mb-0 text-center" variant="danger">EEEERRRRRRRRRR(*weird teacher noises*)</Alert>
                    :
                    null
            }

            <Button disabled={completedInt} style={{ backgroundColor: '#9A86A4', borderColor: 'black' }} className="w-100 mt-2" onClick={() => checkAnswer()}>Enter</Button>

            {
                completedInt ?
                    <div>
                        <Form.Group>
                            <Form.Label>And?</Form.Label>
                            <Form.Control type='text' value={inputText2} onChange={(e) => {
                                setInputText2(e.target.value)
                                setAlert2Shown(false)
                            }} />
                        </Form.Group>

                        {
                            alert2Shown ?
                                <Alert className="mt-2 mb-0 text-center" variant="danger">EEEERRRRRRRRRR(*weird teacher noises*)</Alert>
                                :
                                null
                        }

                        <Button disabled={completed} style={{ backgroundColor: '#9A86A4', borderColor: 'black' }} className="w-100 mt-2" onClick={() => checkAnswer2()}>Enter</Button>
                    </div>
                    :
                    null
            }

            {
                completed ?
                    <div className="mt-2">
                        <div className="d-flex justify-content-center w-100">
                            <img alt="celebration pic" className="w-25" src='https://lh3.googleusercontent.com/UUaVNHHg4Y0NTYAzk19Dbj89QbE4xUJaVD0f_OWKv4UlWP_LHBlM-LasVzzPCjiqpC-LyhzFfi3_P38w6PniEBYrszjrZNtJSFpCGdHpACUzerVLzcT_XqTe7IqzoyXv94JcFAuSLg=w2400' />
                        </div>
                        <Button className="mt-2 w-100" style={{ color: 'black', backgroundColor: '#F6C6EA', borderColor: 'black' }} onClick={() => props.next()}>Next Clue</Button>
                    </div>
                    :
                    null
            }
        </Form>
    )
}

export default Clue25