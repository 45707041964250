import React, { useState } from "react";
import { Button, Form, ListGroup, ListGroupItem, Row, Col, Alert } from "react-bootstrap";

const phrases = [
    "sudoku",
    "logic puzzle",
    "read"
]

function Clue6(props) {
    const [completed, setCompleted] = useState(false)
    const [hintShown, setHintShown] = useState(false)

    const [firstCompleted, setFirstCompleted] = useState(false)

    const [gottenAnswers, setGottenAnswers] = useState([])

    const [inputText, setInputText] = useState('')

    const [input2Text, setInput2Text] = useState('')
    const [alertShown, setAlertShown] = useState(false)

    const answerInput = (text) => {
        if (!gottenAnswers.find(e => e === text.toLowerCase())) {
            const match = phrases.find(e => e === text.toLowerCase())

            if (match) {
                updateGotten(match)
            }
        }
    }

    const updateGotten = (newAnswer) => {
        let newAnswers = gottenAnswers.slice()
        newAnswers.push(newAnswer)
        setGottenAnswers(newAnswers)
        setInputText('')

        if (gottenAnswers.length === 2) {
            setFirstCompleted(true)
        }
    }

    const checkAnswer = () => {
        if (input2Text.toLowerCase() === 'you are perfection') {
            setCompleted(true)
        } else {
            setInputText('')
            setAlertShown(true)
        }
    }

    return (
        <Form>
            <Form.Group>
                <Form.Label as={'h2'}>Clue #6</Form.Label>
            </Form.Group>
            <hr />
            <Form.Group>
                <Form.Label as={'h4'}>We are a team. Our problem solving skills together are unrivaled. But the individual PIECES of a team are important too. Answer the question to get the next step of the PUZZLE. What did we do to keep our minds busy at sharetea?</Form.Label>
            </Form.Group>
            <Button onClick={() => (hintShown ? setHintShown(false) : setHintShown(true))} style={{ backgroundColor: '#9A86A4', borderColor: 'black' }}>Hint</Button>
            {
                hintShown ?
                    <Form.Group>
                        <Form.Label as={'h5'} className='text-muted mt-2'>
                            Its easy! Name the 3 things we did at sharetea. Other than drinking delicious boba of course!
                        </Form.Label>
                    </Form.Group>
                    :
                    null
            }
            <hr />
            <Form.Group>
                <Form.Label>Answer(no punctuation):</Form.Label>
                <Form.Control disabled={firstCompleted} value={inputText} type='text' size="lg" onChange={(e) => {
                    setInputText(e.target.value)
                    answerInput(e.target.value)
                }} />
            </Form.Group>

            <div className="d-flex justify-content-center w-100">
                <ListGroup className="w-50 mt-2 p-1">
                    {
                        gottenAnswers.length === 0 ?
                            <ListGroupItem className="text-center">(no answers yet)</ListGroupItem>
                            :
                            null
                    }
                    {
                        gottenAnswers.map((P, i) => {
                            return (
                                <ListGroupItem className="text-center" key={i}>{P}</ListGroupItem>
                            )
                        })
                    }
                </ListGroup>
            </div>

            {
                firstCompleted ?
                    <div>
                        <Form.Group className="mt-2">
                            <Form.Label as={'h4'}>Congrats on completing the first portion but now you need to prove you have what it takes.</Form.Label>
                            <Form.Label>Solve the puzzle and enter the message within:</Form.Label>
                            <div>
                                <iframe title="puzzle" src="https://www.jigsawplanet.com/?rc=play&amp;pid=1e75e4f53364&amp;view=iframe" style={{ width: '100%', height: '600px' }} frameborder={0} allowFullScreen></iframe>
                            </div>
                        </Form.Group>
                        {
                            alertShown ?
                                <Alert className="mt-2 mb-0 text-center" variant="danger">EEEERRRRRRRRRR(*weird teacher noises*)</Alert>
                                :
                                null
                        }
                        <Row className="mt-2">
                            <Form.Group as={Col}>
                                <Form.Control disabled={completed} value={input2Text} type='text' onChange={(e) => {
                                    setInput2Text(e.target.value)
                                    setAlertShown(false)
                                }} />
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Button onClick={() => checkAnswer()} style={{ backgroundColor: '#9A86A4', borderColor: 'black' }} className="w-100">Submit</Button>
                            </Form.Group>
                        </Row>
                    </div>
                    :
                    null
            }

            {
                completed ?
                    <div className="mt-2">
                        <div className="d-flex justify-content-center w-100">
                            <img alt="celebration pic" className="w-25" src='https://lh3.googleusercontent.com/UUaVNHHg4Y0NTYAzk19Dbj89QbE4xUJaVD0f_OWKv4UlWP_LHBlM-LasVzzPCjiqpC-LyhzFfi3_P38w6PniEBYrszjrZNtJSFpCGdHpACUzerVLzcT_XqTe7IqzoyXv94JcFAuSLg=w2400' />
                        </div>
                        <Button className="mt-2 w-100" style={{ color: 'black', backgroundColor: '#F6C6EA', borderColor: 'black' }} onClick={() => props.next()}>Next Clue</Button>
                    </div>
                    :
                    null
            }
        </Form >
    )
}

export default Clue6